export const unverified = {
  rank: 0,
  image: 'https://gobstaticfiles.s3.amazonaws.com/Badges/00-Unverified.png',
  name: 'Unverified',
  description: 'Verify your email to unlock features',
  required_xp: null,
};

export const levels = [
  {
    rank: 0,
    image: 'https://gobstaticfiles.s3.amazonaws.com/Badges/00-Unverified.png',
    name: 'Verified',
    description: 'Badge silhouette',
    required_xp: 0,
  },
  {
    rank: 1,
    image: 'https://gobstaticfiles.s3.amazonaws.com/Badges/01-Stone.png',
    name: 'Rank 1: Stone',
    description: 'Stone icon ',
    required_xp: 'Verified',
  },
  {
    rank: 2,
    image: 'https://gobstaticfiles.s3.amazonaws.com/Badges/02-Bronze.png',
    name: 'Rank 2: Bronze',
    description: 'Solid bronze icon',
    required_xp: 650,
  },
  {
    rank: 3,
    image: 'https://gobstaticfiles.s3.amazonaws.com/Badges/03-Silver.png',
    name: 'Rank 3: Silver',
    description: 'Silver with Manhattan glow',
    required_xp: 1400,
  },
  {
    rank: 4,
    image: 'https://gobstaticfiles.s3.amazonaws.com/Badges/04-Sapphire.png',
    name: 'Rank 4: Sapphire',
    description: 'Sapphire gemstone with peacock details',
    required_xp: 2900,
  },
  {
    rank: 5,
    image: 'https://gobstaticfiles.s3.amazonaws.com/Badges/05-Ruby.png',
    name: 'Rank 5: Ruby',
    description: 'Ruby gemstone with orange polkadots',
    required_xp: 5150,
  },
  {
    rank: 6,
    image: 'https://gobstaticfiles.s3.amazonaws.com/Badges/06-Gold.png',
    name: 'Rank 6: Gold',
    description: 'Solid Gold stone ',
    required_xp: 8150,
  },
  {
    rank: 7,
    image: 'https://gobstaticfiles.s3.amazonaws.com/Badges/07-Platinum.png',
    name: 'Rank 7: Platinum',
    description: 'Platinum with robot elements',
    required_xp: 11900,
  },
  {
    rank: 8,
    image: 'https://gobstaticfiles.s3.amazonaws.com/Badges/08-Opal.png',
    name: 'Rank 8: Opal',
    description: 'Black Opal with tribal engraving ',
    required_xp: 16400,
  },
  {
    rank: 9,
    image: 'https://gobstaticfiles.s3.amazonaws.com/Badges/09-Diamond.png',
    name: 'Rank 9: Diamond',
    description: 'Diamond stone with LSD details',
    required_xp: 21650,
  },
  {
    rank: 10,
    image: 'https://gobstaticfiles.s3.amazonaws.com/Badges/10-Lava.png',
    name: 'Rank 10: Lava',
    description: 'Black Pearl with lava engraving',
    required_xp: 27650,
  },
];
