/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import type { RootState } from '..';

// const sequence_1 = `https://gobstaticfiles.s3.amazonaws.com/LandVideos/sequence_1.mp4`;
// const sequence_1 = `https://gobstaticfiles.s3.amazonaws.com/LandVideos/sequence_1_audio.mp4`;
const sequence_2 = `https://gobstaticfiles.s3.amazonaws.com/LandVideos/sequence_2.mp4`;

export const updatePercent = createAsyncThunk('ui/land-load-resource-progress', async (percent: number) => percent);

export const loadResources = createAsyncThunk('ui/land-load-resources', async (_, thunkAPI) => {
  const updateProgress = (evt: { loaded: number; total: number }) => {
    thunkAPI.dispatch(updatePercent(Math.floor((evt.loaded * 100) / evt.total)));
  };
  // const p1 = axios
  //   .get(sequence_1, {
  //     responseType: 'blob',
  //   })
  //   .then((res) => URL.createObjectURL(res.data))
  //   .catch((err) => console.log(err));
  const p2 = axios
    .get(sequence_2, {
      responseType: 'blob',
      onDownloadProgress: updateProgress,
    })
    .then((res) => URL.createObjectURL(res.data))
    .catch((err) => console.log(err));
  return Promise.all([p2]);
});

export const updateMainPercent = createAsyncThunk('ui/main-load-resource-progress', async (percent: number) => percent);

export const loadMainResource = createAsyncThunk('ui/main-load-resources', async (source: string, thunkAPI) => {
  const updateProgress = (evt: { loaded: number; total: number }) => {
    thunkAPI.dispatch(updateMainPercent(Math.floor((evt.loaded * 100) / evt.total)));
  };

  const p = axios
    .get(source, {
      responseType: 'blob',
      onDownloadProgress: updateProgress,
    })
    .then((res) => URL.createObjectURL(res.data))
    .catch((err) => console.log(err));
  return Promise.all([p]);
});

export const updatePackPercent = createAsyncThunk(
  'ui/pack-load-resources-progress',
  async (percent: number) => percent
);

export const loadPackResource = createAsyncThunk('ui/pack-load-resources', async (sources: string[], thunkAPI) => {
  let progress = 0;
  return Promise.all(
    sources.map((source) => {
      let previous = 0;
      return axios
        .get(source, {
          responseType: 'blob',
          onDownloadProgress: (evt: { loaded: number; total: number }) => {
            const current = Math.round((evt.loaded * 100) / evt.total / sources.length);

            progress += current - previous;
            previous = current;
            thunkAPI.dispatch(updatePackPercent(progress));
          },
        })
        .then((res) => URL.createObjectURL(res.data))
        .catch((err) => console.log(err));
    })
  );
});

export const loadExploreResource = createAsyncThunk('ui/explore-load-resources', async (count: number) => count);

export const updateExplorePercent = createAsyncThunk('ui/explore-load-resources-progress', async (_, { getState }) => {
  const { loaded, total } = (getState() as RootState).ui.explore;
  const percent = Math.round(((loaded + 1) / total) * 100);
  return {
    percent,
    loaded: loaded + 1,
  };
});
