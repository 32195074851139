import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { goerli, mainnet } from 'wagmi/chains';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { publicProvider } from 'wagmi/providers/public';

import type { FC, ReactNode } from 'react';

const { chains, provider, webSocketProvider } = configureChains([mainnet, goerli], [publicProvider()], {
  targetQuorum: 1,
});

const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({
      chains,
      options: {
        UNSTABLE_shimOnConnectSelectAccount: true,
      },
    }),
    // new CoinbaseWalletConnector({
    //   options: {
    //     appName: CONFIG.alchemy.name,
    //     jsonRpcUrl: CONFIG.alchemy.jsonRpcUrl,
    //   },
    // }),
    // new WalletConnectConnector({
    //   chains,
    //   options: {
    //     qrcode: true,
    //   },
    // }),
  ],
  provider,
  webSocketProvider,
});

type WagmiProviderProps = {
  children: ReactNode;
};

export const WagmiProvider: FC<WagmiProviderProps> = ({ children }) => {
  return <WagmiConfig client={client}>{children}</WagmiConfig>;
};
