import type { StoreUserModel, UserLoginModel } from 'types/user';

import { mainApi, storeApi } from './BaseApi';

export const setWalletStoreApi = (wallet: string) =>
  storeApi
    .callApi<StoreUserModel>({
      method: 'POST',
      url: '/api/v1/setup-wallet',
      withCredentials: true,
      data: { wallet },
    })
    .then((result) => result.data);

export const userLoginApi = async <T>(ethAddress: string) => {
  let data = {};

  if (localStorage.getItem('referralRefId')) {
    data = { referralRefId: localStorage.getItem('referralRefId') };
  }

  return mainApi
    .callApi<T>({
      method: 'POST',
      url: `/api/users/login/${ethAddress}`,
      data,
    })
    .then((result) => result.data);
};

interface SetEmail {
  email: string;
  name: string;
  walletId: string;
}

export const setEmailApi = <T>(params: SetEmail) =>
  mainApi
    .callApi<T>({
      data: params,
      method: 'POST',
      url: `/api/users/profile`,
    })
    .then((result) => result.data);

export const getUserInfoApi = <T>(wallet: string) =>
  mainApi
    .callApi<T>({
      method: 'GET',
      url: `/api/users/get-user-info/${wallet}`,
    })
    .then((result) => result.data);

export const getReferralInfoApi = <T>(wallet: string) =>
  mainApi
    .callApi<T>({
      url: `/api/users/referralInfo/${wallet}`,
    })
    .then((result) => result.data);

interface CheckUserAndEmail {
  email?: string;
  username?: string;
}

export const checkNameAndEmailApi = <T>(params: CheckUserAndEmail) =>
  mainApi
    .callApi<T>({
      data: params,
      method: 'POST',
      url: `/api/auth/check-name-email`,
    })
    .then((result) => result.data);

export const checkReferralIdApi = <T>(params: { referralCode: string }) =>
  mainApi
    .callApi<T>({
      data: params,
      method: 'POST',
      url: `/api/auth/check-referral-code`,
    })
    .then((result) => result.data);

interface ConfirmEmail {
  email: string;
  walletId: string;
  code: string;
}

export const confirmEmailApi = <T>(params: ConfirmEmail) =>
  mainApi
    .callApi<T>({
      data: params,
      method: 'POST',
      url: `/api/users/confirm`,
    })
    .then((result) => result.data);

export const setReferralApi = <T>(code: string) =>
  mainApi
    .callApi<T>({
      url: `/api/join/${code}`,
    })
    .then((result) => result.data);

export const getMeApi = () =>
  mainApi
    .callApi<UserLoginModel>({
      url: '/api/users/me',
    })
    .then((result) => result.data);
