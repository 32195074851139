import { mainApi, rewardApi } from './BaseApi';

export const getTotalReservedApi = <T>() =>
  rewardApi
    .callApi<T>({
      url: `/api/land/total-reserved`,
    })
    .then((result) => result.data);

export const getReversePriceApi = <T>(wallet: string) =>
  mainApi
    .callApi<T>({
      url: `/api/land/price/${wallet}`,
    })
    .then((result) => result.data);

export const getReservePeriodApi = <T>() =>
  mainApi
    .callApi<T>({
      url: `/api/land/presale-period/`,
    })
    .then((result) => result.data);

export interface BuyLandPayload {
  walletId: string;
  lands: number;
  amount: number;
}

export const buyLandApi = <T>(data: BuyLandPayload) =>
  mainApi
    .callApi<T>({
      method: 'POST',

      url: `/api/land/land-spend`,
      data: {
        lands: data.lands,
        amount: data.amount,
      },
    })
    .then((result) => result.data);
