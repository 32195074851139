export type ScreenSize = keyof typeof MediaQueries['MIN_WIDTHS'];

export class MediaQueries {
  public static readonly MAX_WIDTHS = {
    xm: 320,
    sm: 375,
    lm: 475,
    md: 968,
    lg: 1024,
    ld: 1200,
    xl: 1440,
    hd: 1600,
    xxl: 2560,
  };

  public static readonly MIN_WIDTHS = {
    xs: MediaQueries.MAX_WIDTHS.xm + 1,
    sm: MediaQueries.MAX_WIDTHS.sm + 1,
    lm: MediaQueries.MAX_WIDTHS.lm + 1,
    md: MediaQueries.MAX_WIDTHS.md + 1,
    lg: MediaQueries.MAX_WIDTHS.lg + 1,
    ld: MediaQueries.MAX_WIDTHS.ld + 1,
    xl: MediaQueries.MAX_WIDTHS.xl + 1,
    hd: MediaQueries.MAX_WIDTHS.hd + 1,
    xxl: MediaQueries.MAX_WIDTHS.xxl + 1,
  };

  public static between(screenSize: ScreenSize, screenSize2: ScreenSize) {
    const MAX_WIDTH = Math.max(MediaQueries.MAX_WIDTHS[screenSize], MediaQueries.MAX_WIDTHS[screenSize2]);
    const MIN_WIDTH = Math.min(MediaQueries.MIN_WIDTHS[screenSize], MediaQueries.MIN_WIDTHS[screenSize2]);

    const hasMin = Number.isFinite(MIN_WIDTH);
    const hasMax = Number.isFinite(MAX_WIDTH);
    if (hasMin && hasMax) {
      return `@media only screen and (min-width: ${MIN_WIDTH}px) and (max-width: ${MAX_WIDTH}px)`;
    }
    if (hasMax) {
      return `@media only screen and (max-width: ${MAX_WIDTH}px)`;
    }
    if (hasMin) {
      return `@media only screen and (min-width: ${MIN_WIDTH}px)`;
    }
    return `@media only screen and (min-width: 0px)`;
  }

  public static down(screenSize: ScreenSize) {
    const MAX_WIDTH = MediaQueries.MAX_WIDTHS[screenSize];
    const hasMax = Number.isFinite(MAX_WIDTH);
    if (hasMax) {
      return `@media only screen and (max-width: ${MAX_WIDTH}px)`;
    }
    return `@media only screen and (min-width: 0px)`;
  }

  public static only(screenSize: ScreenSize) {
    const MAX_WIDTH = MediaQueries.MAX_WIDTHS[screenSize];
    const MIN_WIDTH: number = MediaQueries.MIN_WIDTHS[screenSize];

    const hasMin = Number.isFinite(MIN_WIDTH);
    const hasMax = Number.isFinite(MAX_WIDTH);
    if (hasMin && hasMax) {
      return `@media only screen and (min-width: ${MAX_WIDTH}px) and (max-width: ${MIN_WIDTH}px)`;
    }
    if (hasMax) {
      return `@media only screen and (max-width: ${MAX_WIDTH}px)`;
    }
    if (hasMin) {
      return `@media only screen and (min-width: ${MIN_WIDTH}px)`;
    }
    return `@media only screen and (min-width: 0px)`;
  }

  public static up(screenSize: ScreenSize) {
    const MIN_WIDTH: number = MediaQueries.MIN_WIDTHS[screenSize];
    const hasMin = Number.isFinite(MIN_WIDTH);

    if (hasMin) {
      return `@media only screen and (min-width: ${MIN_WIDTH}px)`;
    }
    return `@media only screen and (min-width: 0px)`;
  }
}
