import { createAsyncThunk } from '@reduxjs/toolkit';

import type { BuyLandPayload } from '../../apis/reserveApi';

import { getReservePeriodApi, getReversePriceApi, getTotalReservedApi, buyLandApi } from '../../apis/reserveApi';

export const getReservePeriod = createAsyncThunk('reserve/ReservePeriod', async (_, thunkAPI) => {
  try {
    return getReservePeriodApi();
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getTotalReserved = createAsyncThunk('reserve/TotalReserved', async (_, thunkAPI) => {
  try {
    return getTotalReservedApi();
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getReservePrice = createAsyncThunk('reserve/ReservePrice', async (walletId: string, thunkAPI) => {
  try {
    return getReversePriceApi(walletId);
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const buyLand = createAsyncThunk('reserve/BuyLand', async (data: BuyLandPayload, thunkAPI) => {
  try {
    const res = await buyLandApi(data);
    await thunkAPI.dispatch(getTotalReserved());
    await thunkAPI.dispatch(getReservePrice(data.walletId));
    return res;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});
