import { configureStore } from '@reduxjs/toolkit';

import claimReducer from './claim/claimSlice';
import reserveReducer from './reserve/reserveSlice';
import userReducer from './user/slices';
import rewardReducer from './rewards/slices';
import assetReducer from './assets/slices';
import notificationReducer from './notification/slice';
import uiReducer from './ui/slices';

export const store = configureStore({
  reducer: {
    claim: claimReducer,
    reserve: reserveReducer,
    rewards: rewardReducer,
    user: userReducer,
    assets: assetReducer,
    notification: notificationReducer,
    ui: uiReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
