import { createSlice } from '@reduxjs/toolkit';
import { logoutUser } from '@store/user';

import type { SimpleAssetModel } from 'types/Asset';
import type { GalleryItemModel, SimpleCardModel } from '../../types/Card';

import { getAllCards, getMyAvatars, getMyBods, getMyCards, getMyLands, getReleasedCards } from './actions';

export interface AssetsModel {
  loading: boolean;
  cards: GalleryItemModel[];
  releasedCards: number[];
  myAvatars: SimpleAssetModel[];
  myBods: SimpleAssetModel[];
  myCards: SimpleCardModel[];
  myLands: SimpleAssetModel[];
}

const initialState: AssetsModel = {
  loading: false,
  cards: [],
  releasedCards: [],
  myAvatars: [],
  myBods: [],
  myCards: [],
  myLands: [],
};

const slice = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCards.pending, (state) => {
      state.loading = true;
      state.cards = [];
    });
    builder.addCase(getAllCards.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.cards = payload;
    });
    builder.addCase(getAllCards.rejected, (state) => {
      state.loading = false;
      state.cards = [];
    });
    builder.addCase(getReleasedCards.pending, (state) => {
      state.loading = true;
      state.releasedCards = [];
    });
    builder.addCase(getReleasedCards.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.cards = state.cards.map((e, id) => ({ ...e, released: payload.includes(id) }));
      state.releasedCards = payload;
    });
    builder.addCase(getReleasedCards.rejected, (state) => {
      state.loading = false;
      state.releasedCards = [];
    });
    // builder.addCase(getMyCards.pending, (state) => {
    //   state.loading = true;
    // });
    builder.addCase(getMyCards.fulfilled, (state, { payload }) => {
      state.myCards = payload;
    });
    builder.addCase(getMyCards.rejected, (state) => {
      state.myCards = [];
    });
    builder.addCase(getMyAvatars.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMyAvatars.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.myAvatars = payload;
    });
    builder.addCase(getMyAvatars.rejected, (state) => {
      state.loading = false;
      state.myAvatars = [];
    });
    builder.addCase(getMyBods.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMyBods.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.myBods = payload;
    });
    builder.addCase(getMyBods.rejected, (state) => {
      state.loading = false;
      state.myBods = [];
    });
    builder.addCase(getMyLands.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMyLands.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.myLands = payload;
    });
    builder.addCase(getMyLands.rejected, (state) => {
      state.loading = false;
      state.myBods = [];
    });
    builder.addCase(logoutUser.type, (state) => {
      state.myAvatars = [];
      state.myBods = [];
      state.myCards = [];
    });
  },
});

export default slice.reducer;
