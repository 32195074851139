import { MESSAGE_KEYS } from 'functions/notification';

import type { ApiResponse } from 'types/ApiResponse';

export const extractResponseCode = (err: unknown): string => {
  const castCased = err as any;
  if (castCased?.code) {
    return castCased.code as string;
  }
  const errObj = castCased?.response?.data as ApiResponse;

  if (errObj) {
    return errObj.code;
  }

  return MESSAGE_KEYS.WENT_WRONG;
};
