import type { ReleaseBalancesInterface } from 'types/Rewards';

import { claimApi } from './BaseApi';

export const getReleaseBalancesApi = (wallet: string) =>
  claimApi
    .callApi<ReleaseBalancesInterface>({
      url: `/api/gob-claim/user-balances/${wallet}`,
    })
    .then((result) => result.data);

export const getReleaseVersionApi = () =>
  claimApi
    .callApi<'v1' | 'v2'>({
      url: `/api/gob-claim/version`,
    })
    .then((result) => result.data);

export const claimAirdropMaticApi = (walletId: string) =>
  claimApi
    .callApi<boolean>({
      data: { walletId },
      method: 'POST',
      url: `/api/gob-claim/airdrop-matic`,
    })
    .then((result) => result.data);
