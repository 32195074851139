import { levels, unverified } from 'constants/levels';

const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

export const isMobileDevice = () => {
  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const getUserLevelText = (level: number, verified?: boolean): string => {
  if (!verified) {
    return 'Verify Yourself to Unlock Rank Status';
  }
  if (!level && verified) {
    return 'verification completed';
  }

  const levelObj = levels[level] || levels[1];
  return levelObj.name;
};

export const getUserLevelPicture = (level: number) => {
  const levelObj = levels[level] || levels[0];
  return levelObj.image;
};

export const getUserLevelObj = (level: number, verified?: boolean) => {
  if (!verified) {
    return unverified;
  }
  return levels[level] || levels[0];
};

export const obscureEmail = (email: string): string => {
  if (!email) {
    return '';
  }
  const asterisk = (p: string, f: string) => {
    return new Array(f.length - p.length).fill('*').join('');
  };
  const [a, b] = email.split('@');
  const a1 = a.slice(0, 3);
  const n = `${a1}${asterisk(a1, a)}`;
  return `${n}@${b}`;
};

export const useDomain = () => {
  const location = window.location;
  if (location.hostname === 'localhost') {
    return 'localhost';
  }
  const original = (location.hostname || '').split('.');
  if (original.length === 2) {
    return location.hostname;
  }
  original.shift();
  return `.${original.join('.')}`;
};
