import { createSlice } from '@reduxjs/toolkit';

import type { NotificationInterface } from 'types/notification';

import { getNotification, enableNotification, disableNotification } from './actions';

export interface NotificationState {
  notification: NotificationInterface[];
  loading: boolean;
  status: boolean;
}

const initialState: NotificationState = {
  notification: [],
  loading: false,
  status: false,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotification.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getNotification.fulfilled, (state, action) => {
      state.loading = false;
      state.notification = action.payload;
    });
    builder.addCase(enableNotification.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(enableNotification.fulfilled, (state, action) => {
      state.loading = false;
      state.status = true;
    });
    builder.addCase(disableNotification.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(disableNotification.fulfilled, (state, action) => {
      state.loading = false;
      state.status = false;
    });
  },
});

export default notificationSlice.reducer;
