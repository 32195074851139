import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  userAvatarStatusApi,
  getSupplyLimitApi,
  claimPackWidthAvatarsApi,
  claimAvatarsByGobApi,
  getFloorPriceApi,
} from '../../apis/claim';

export const getAvatarStatus = createAsyncThunk('claim/avatarStatus', async (_, thunkAPI) => {
  try {
    return userAvatarStatusApi();
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});
export const getSupplyLimit = createAsyncThunk('claim/SupplyLimit', async (_, thunkAPI) => {
  try {
    return getSupplyLimitApi();
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const claimPackWidthAvatar = createAsyncThunk('claim/ClaimPackWidthAvatars', async (_, thunkAPI) => {
  try {
    return claimPackWidthAvatarsApi();
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const claimAvatarsByGob = createAsyncThunk('claim/ClaimAvatarsByGob', async (packs: number, thunkAPI) => {
  try {
    return claimAvatarsByGobApi(packs);
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getFloorPrice = createAsyncThunk('claim/AvatarFloorPrice', async (_, thunkAPI) => {
  try {
    return getFloorPriceApi();
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});
