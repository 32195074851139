import type { ArticleListItem, ArticleResponse } from 'types/ApiResponse';
import type { NotificationInterface } from 'types/notification';

import { mainApi } from './BaseApi';

export const getNotificationApi = () =>
  mainApi
    .callApi<NotificationInterface[]>({
      method: 'GET',
      url: `/api/notification`,
    })
    .then((result) => result.data);

export const disableNotificationApi = <T>() =>
  mainApi
    .callApi<T>({
      method: 'POST',
      url: `/api/notification/disable`,
    })
    .then((result) => result.data);

export const enableNotificationApi = <T>() =>
  mainApi
    .callApi<T>({
      method: 'POST',
      url: `/api/notification/enable`,
    })
    .then((result) => result.data);

export const getArticlesApi = () =>
  mainApi
    .callApi<ArticleListItem[]>({
      method: 'GET',
      url: `api/articles/list`,
    })
    .then((result) => result.data);

export const getArticleApi = (slug: string) =>
  mainApi
    .callApi<ArticleResponse>({
      method: 'GET',
      url: `api/articles/slug/${slug}`,
    })
    .then((result) => result.data);
