import { createSlice } from '@reduxjs/toolkit';

import { getAvatarStatus, getSupplyLimit, claimPackWidthAvatar, getFloorPrice } from './claimActions';

export interface ClaimState {
  avatars: {
    total_avatars: number;
    total_avatars_unclaimed: number;
    avatars_unclaimed: number[];
    user_avatars: number[];
  };
  supplies: {
    total_limit: number;
    total_claimed: number;
  };
  loading: boolean;
  floorPrices: {
    avatar: number;
    bod: number;
  };
}

const initialState: ClaimState = {
  avatars: null,
  supplies: null,
  loading: false,
  floorPrices: null,
};

export const authSlice = createSlice({
  name: 'claim',
  initialState,
  reducers: {
    setIinitialized: (state) => {
      state.avatars = null;
      state.supplies = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAvatarStatus.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAvatarStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.avatars = action.payload as any;
    });
    builder.addCase(getSupplyLimit.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSupplyLimit.fulfilled, (state, action) => {
      state.loading = false;
      state.supplies = action.payload as any;
    });
    builder.addCase(claimPackWidthAvatar.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(claimPackWidthAvatar.fulfilled, (state, action) => {
      state.loading = false;
      // state.isAuthenticated = true;
      state.supplies = action.payload as any;
    });
    builder.addCase(getFloorPrice.pending, (state, action) => {
      state.loading = true;
      state.floorPrices = null;
    });
    builder.addCase(getFloorPrice.fulfilled, (state, action) => {
      state.loading = false;
      state.floorPrices = (action.payload as any)?.reduce((sum, cur) => {
        const key = cur?.collection.toLowerCase();
        sum[key] = cur?.floorPrice;
        return sum;
      }, {});
    });
  },
});

// export const selectProducts = createSelector(
//   state => ({
//     authenticated: state.auth.isAuthenticated,
//     userInfo: state.auth.userInfo,
//     loading: state.auth.loading
//   }),
//   state => state
// );

export default authSlice.reducer;
