import { createSlice } from '@reduxjs/toolkit';

import type { ReleaseBalancesInterface } from 'types/Rewards';

import { getReleaseBalances } from './actions';
export interface RewardState {
  pending: boolean;
  releaseBalances: ReleaseBalancesInterface | null;
}

const initialState: RewardState = {
  pending: false,
  releaseBalances: null,
};

export const rewardSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    resetClaimableGob(state) {
      if (state.releaseBalances) {
        state.releaseBalances.claimableGobs = 0;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReleaseBalances.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(getReleaseBalances.fulfilled, (state, action) => {
      state.pending = false;
      state.releaseBalances = action.payload;
    });
    builder.addCase(getReleaseBalances.rejected, (state) => {
      state.pending = false;
      state.releaseBalances = null;
    });
  },
});

export default rewardSlice.reducer;
