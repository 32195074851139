import { CONFIG } from 'constants/config';

import { notification } from 'antd';

export enum MESSAGE_KEYS {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  BLOCKED_EMAIL = 'blockedEmail',
  BUY_LAND_ERROR = 'buyLandError',
  CLAIMED_PACKS = 'claimedPacks',
  CLAIMED_REWARDS = 'gobRewardClaimSuccess',
  COPIED_CLIPBOARD = 'copiedToClipboard',
  DUPLICATED_EMAIL = 'duplicatedEmail',
  DUPLICATED_USERNAME = 'duplicatedUsername',
  GOB_REQUIRED = 'gobRequired',
  REJECTED_IMX = 'imxClosed',
  INVALID_CODE = 'invalidCode',
  INVALID_EMAIL = 'invalidEmail',
  INVALID_IMX_WALLET = 'invalidImxWallet',
  INVALID_REFERRAL_CODE = 'invalidReferralCode',
  INVALID_USERNAME = 'invalidUsername',
  LOGIN_SUCCESS = 'loggedIn',
  LOGIN_FAILED = 'loginFailed',
  LOGIN_WITH_EMAIL_FAILED = 'loginEmailFailed',
  MAX_SUPPLY_ERR = 'maxSupplyError',
  NOT_FOUND_METAMASK = 'metamaskNotInstalled',
  MINT_ERR = 'mintError',
  MINT_SUCCESS = 'mintSuccess',
  NAME_HAS_INVALID_CHAR = 'nameHasSymbolOrSpace',
  NAME_LENGTH_ERR = 'nameLengthIsInvalid',
  NAME_START_WITH_NUMBER = 'nameStartedWithNumber',
  NO_DETECT_ACCOUNT = 'notDetectAccount',
  REACHED_MAX_LEVEL = 'reachedMaxLevel',
  REACHED_SUPPLY_LIMIT = 'reachedSupplyLimit',
  REFERRAL_SUCCESS = 'referralSuccess',
  RESERVED_SUCCESS = 'reservedSucceed',
  CHANGE_AVATAR_NAME = 'changeAvatarName',
  RESET_AVATAR_NAME = 'resetAvatarName',
  FORGOT_PASSWORD_FAILED = 'sendForgotPasswordEmailFailed',
  SENT_VERIFY_CODE = 'sentCode',
  SET_USER_PICTURE = 'setUserPictureSuccess',
  SUBSCRIBED = 'subscribed',
  TRANSACTION_FAILED = 'transactionFailed',
  TRANSACTION_SUCCESS = 'transactionSucceed',
  UNVERIFIED_USER = 'unverifiedUser',
  SET_PASSWORD_FAILED = 'updatePasswordFailed',
  SET_PASSWORD_SUCCESS = 'updatePasswordSucceed',
  UPDATED_EMAIL = 'updatedEmail',
  UPDATED_PROFILE = 'updatedProfile',
  UPDATED_USERNAME = 'updatedUsername',
  NOT_FOUND_USER = 'userNotFound',
  VERIFIED_EMAIL = 'verifiedEmail',
  WENT_WRONG = 'wentWrong',
  XP_REQUIRED = 'xpRequired',
  USERNAME_EXIST = 'usernameAlreadyExists',
  USER_EMAIL_EXIST = 'userEmailAlreadyExists',
  WALLET_EXIST = 'walletAlreadyExist',
  INVALID_PASSWORD = 'invalidPassword',
  REJECTED_METAMASK_CONNECT = 'rejectedMetamaskConnect',
  REJECTED_METAMASK_SIGNATURE = 'rejectedMetamaskSignature',
  NO_ENOUGH_MATIC = 'noEnoughMATIC',
  HAS_PENDING_TX = 'hasPendingTransactions',
}

const messages: Record<MESSAGE_KEYS, string> = {
  [MESSAGE_KEYS.INSUFFICIENT_FUNDS]: 'You have insufficient funds!',
  [MESSAGE_KEYS.BLOCKED_EMAIL]: 'Your email provider is not allowed to verify an account. Please choose another one.',
  [MESSAGE_KEYS.BUY_LAND_ERROR]: 'There was an error to buy lands now.',
  [MESSAGE_KEYS.CLAIMED_PACKS]: 'Your transaction has been successfully submitted!',
  [MESSAGE_KEYS.CLAIMED_REWARDS]: 'The transaction has been submitted. Please wait for it to get confirmed.',
  [MESSAGE_KEYS.COPIED_CLIPBOARD]: 'Copied to clipboard!',
  [MESSAGE_KEYS.DUPLICATED_EMAIL]: 'This email is already taken.',
  [MESSAGE_KEYS.DUPLICATED_USERNAME]: 'This username is already taken.',
  [MESSAGE_KEYS.GOB_REQUIRED]: "You don't have enough GOB!",
  [MESSAGE_KEYS.REJECTED_IMX]: 'Immutable x link window was closed!',
  [MESSAGE_KEYS.INVALID_CODE]: "You've provided a wrong code.",
  [MESSAGE_KEYS.INVALID_EMAIL]: 'Your email is invalid.',
  [MESSAGE_KEYS.INVALID_IMX_WALLET]: "You've selected a different wallet in IMX. Choose the same wallet in IMX.",
  [MESSAGE_KEYS.INVALID_REFERRAL_CODE]: 'This Referral code is invalid.',
  [MESSAGE_KEYS.INVALID_USERNAME]: 'Username is invalid.',
  [MESSAGE_KEYS.LOGIN_SUCCESS]: 'You are successfully logged in.',
  [MESSAGE_KEYS.LOGIN_FAILED]: 'User authentication error!',
  [MESSAGE_KEYS.LOGIN_WITH_EMAIL_FAILED]: 'Email or password entered incorrectly!',
  [MESSAGE_KEYS.MAX_SUPPLY_ERR]: 'You are trying to mint above the maximum supply!',
  [MESSAGE_KEYS.NOT_FOUND_METAMASK]: 'Please install Metamask extension yourself and reload the page!',
  [MESSAGE_KEYS.MINT_ERR]:
    'There was an error leveling up. Please try again or {{contact_us_start}}contact support.{{contact_us_end}}',
  [MESSAGE_KEYS.MINT_SUCCESS]:
    'You ranked up and received an NFT for the new rank! <br/> {{link_start}}Check it out by clicking here.{{link_end}}',
  [MESSAGE_KEYS.NAME_HAS_INVALID_CHAR]: 'Your username cannot contain symbols or spaces.',
  [MESSAGE_KEYS.NAME_LENGTH_ERR]: 'Your username must be between 3-15 characters long.',
  [MESSAGE_KEYS.NAME_START_WITH_NUMBER]: 'Your username cannot start with a number.',
  [MESSAGE_KEYS.NO_DETECT_ACCOUNT]: "We're unable to find your account in our system.",
  [MESSAGE_KEYS.REACHED_MAX_LEVEL]: 'You already reached the maximum possible rank level!',
  [MESSAGE_KEYS.REACHED_SUPPLY_LIMIT]:
    "Unfortunately, you didn't receive a Rank NFT because the supply for this rank is maxed. Visit the Goons of Balatroon Rank collection to buy one instead.",
  [MESSAGE_KEYS.REFERRAL_SUCCESS]: 'Referral request was successful!',
  [MESSAGE_KEYS.RESERVED_SUCCESS]: 'We successfully received your reservation request!',
  [MESSAGE_KEYS.CHANGE_AVATAR_NAME]: "We have successfully changed your avatar's name!",
  [MESSAGE_KEYS.RESET_AVATAR_NAME]: 'We have successfully restored the default name of your avatar!',
  [MESSAGE_KEYS.FORGOT_PASSWORD_FAILED]: "We can't send a forgot password email!",
  [MESSAGE_KEYS.SENT_VERIFY_CODE]: 'Your confirmation code was successfully sent.',
  [MESSAGE_KEYS.SET_USER_PICTURE]: "We've updated your picture successfully.",
  [MESSAGE_KEYS.SUBSCRIBED]: 'You subscribed successfully.',
  [MESSAGE_KEYS.TRANSACTION_FAILED]: 'Your payment was not successful. Please try again.',
  [MESSAGE_KEYS.TRANSACTION_SUCCESS]: 'Submitted your payment successfully.',
  [MESSAGE_KEYS.UNVERIFIED_USER]: 'You are not verified. Please verify yourself before continuing.',
  [MESSAGE_KEYS.SET_PASSWORD_FAILED]:
    'Sorry, we were not able to update your password. Please try again or {{contact_us_start}}contact support{{contact_us_end}}.',
  [MESSAGE_KEYS.SET_PASSWORD_SUCCESS]: 'Please use the updated password from next login.',
  [MESSAGE_KEYS.UPDATED_EMAIL]:
    "We've updated your email and sent an verification email. please check your inbox to get verified your account",
  [MESSAGE_KEYS.UPDATED_PROFILE]: "We've updated your profile with provided. Please check your inbox to get verified!",
  [MESSAGE_KEYS.UPDATED_USERNAME]: "We've updated your username.",
  [MESSAGE_KEYS.NOT_FOUND_USER]: "We're unable to find your account in our system.",
  [MESSAGE_KEYS.VERIFIED_EMAIL]: 'Your account is verified successfully.',
  [MESSAGE_KEYS.XP_REQUIRED]: "You don't have enough XP to level up!",
  [MESSAGE_KEYS.WENT_WRONG]:
    "We're sorry inform you that operation went wrong. Please try later or {{contact_us_start}}contact us!{{contact_us_end}}",
  [MESSAGE_KEYS.USERNAME_EXIST]: 'This username is already taken.',
  [MESSAGE_KEYS.USER_EMAIL_EXIST]: 'This email is already taken.',
  [MESSAGE_KEYS.WALLET_EXIST]: 'This wallet is already taken',
  [MESSAGE_KEYS.INVALID_PASSWORD]: 'Password is wrong!',
  // TODO: use different message!
  [MESSAGE_KEYS.REJECTED_METAMASK_CONNECT]: 'MetaMask window closed before all steps were completed. Please try again.',
  [MESSAGE_KEYS.REJECTED_METAMASK_SIGNATURE]:
    'MetaMask window closed before all steps were completed. Please try again.',
  [MESSAGE_KEYS.NO_ENOUGH_MATIC]:
    "You don't have enough MATIC in your account to pay for transaction fees. Buy MATIC or deposit from another account.",
  [MESSAGE_KEYS.HAS_PENDING_TX]: 'You have some pending transactions. please try later!',
};

type Replacement = {
  msg?: string;
  countOfPacks?: string;
  link?: string;
};

const defaultDuration = 10; // in sec

const formatMessage = (msg?: string, obj?: Replacement) => {
  if (!msg) {
    return '';
  }
  const replObj = obj ?? {};
  let tempMsg = msg;

  Object.keys(replObj ?? {}).forEach((k) => {
    if (k !== 'link') {
      tempMsg = tempMsg.replace(`{{${k}}}`, obj[k]);
    }
  });

  if (replObj.link) {
    const link_start = `<a href="${replObj.link}" target="_blank">`;
    const link_end = '</a>';
    tempMsg = tempMsg.replace('{{link_start}}', link_start).replace('{{link_end}}', link_end);
  }

  if (tempMsg.includes('contact_us_start')) {
    const link_start = `<a href="${CONFIG.urls.gForm.contact}" target="_blank">`;
    const link_end = '</a>';
    tempMsg = tempMsg.replace('{{contact_us_start}}', link_start).replace('{{contact_us_end}}', link_end);
  }

  return tempMsg;
};

export const errorNotify = (errorCode: MESSAGE_KEYS | string, replacement?: Replacement, duration?: number) => {
  const msg = messages[errorCode] || messages[MESSAGE_KEYS.WENT_WRONG];

  notification.error({
    message: '',
    duration: duration || defaultDuration,
    description: <span dangerouslySetInnerHTML={{ __html: formatMessage(msg, replacement) }} />,
  });
};

export const infoNotify = (messageCode: MESSAGE_KEYS, replacement?: Replacement, duration?: number) => {
  const msg = messages[messageCode] || '';
  notification.info({
    message: '',
    duration: duration || defaultDuration,
    description: <span dangerouslySetInnerHTML={{ __html: formatMessage(msg, replacement) }} />,
  });
};

export const successNotify = (messageCode: MESSAGE_KEYS | string, replacement?: Replacement, duration?: number) => {
  const msg = messages[messageCode] || '';

  notification.success({
    message: '',
    duration: duration || defaultDuration,
    description: <span dangerouslySetInnerHTML={{ __html: formatMessage(msg, replacement) }} />,
  });
};

export const getCopy = (messageCode: MESSAGE_KEYS): string => {
  if (messages[messageCode]) {
    return messages[messageCode];
  }
  return formatMessage(messages[MESSAGE_KEYS.WENT_WRONG]);
};
