import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { ReferralInfoModel } from '../../types/Referral';
import type { AchievementModel, StoreUserModel, UserLoginModel, UserProfileModel } from '../../types/user';

import {
  claimReward,
  getAchievements,
  getMeAction,
  getProfile,
  levelUp,
  setWalletStore,
  userLoginWithEmail,
} from './actions';

export interface UserState {
  loading: boolean;
  pending: boolean;
  pendingProfile: boolean;
  pendingClaim: string | null;
  isAuthenticated: boolean;
  requested: string | null;
  store_user: StoreUserModel | null;
  simplified: UserLoginModel | null;
  profile: UserProfileModel | null;
  achievements: AchievementModel[] | null;
  referralInfo?: ReferralInfoModel;
  showVerifyBox: boolean;
}

const initialState: UserState = {
  loading: true,
  pending: false,
  requested: null,
  pendingProfile: true,
  pendingClaim: null,
  showVerifyBox: true,
  isAuthenticated: false,
  simplified: null,
  profile: null,
  achievements: null,
  store_user: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logoutUser(state) {
      state.isAuthenticated = false;
      state.simplified = null;
      state.profile = null;
      state.pendingProfile = true;
      state.store_user = null;
      state.loading = false;
    },
    hideVerifyBox(state) {
      state.showVerifyBox = false;
    },
    cancelLoadingProfile(state) {
      state.pendingProfile = false;
    },
    updateUserPicture(state, action) {
      if (state.simplified) {
        state.simplified.pfpAvatarImageUrl = action.payload;
      }
    },
    setSimplifiedUser(state, action: PayloadAction<{ loading: boolean; user: UserLoginModel | null }>) {
      state.loading = action.payload.loading;
      state.requested = window?.location?.pathname.split('/')[1] ?? null;
      state.simplified = action.payload.user;

      if (state.simplified) {
        state.isAuthenticated = true;
        state.showVerifyBox = true;
      } else {
        state.isAuthenticated = false;
      }
    },
    setStoreUser(state, action: PayloadAction<{ loading: boolean; user: StoreUserModel | null }>) {
      state.pending = action.payload.loading;
      state.store_user = action.payload.user;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLoginWithEmail.pending, (state) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.showVerifyBox = false;
      state.simplified = null;
    });
    builder.addCase(userLoginWithEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.showVerifyBox = true;
      state.simplified = action.payload;
    });
    builder.addCase(getMeAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMeAction.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.showVerifyBox = true;
      state.simplified = action.payload;
    });
    builder.addCase(getMeAction.rejected, (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
    });
    builder.addCase(getProfile.pending, (state) => {
      state.pendingProfile = true;
    });
    builder.addCase(getProfile.rejected, (state) => {
      state.pendingProfile = false;
      state.profile = null;
      state.referralInfo = null;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.pendingProfile = false;
      state.profile = action.payload.profile;
      state.referralInfo = action.payload.referral;
    });

    builder.addCase(getAchievements.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAchievements.rejected, (state) => {
      state.loading = false;
      state.achievements = null;
    });
    builder.addCase(getAchievements.fulfilled, (state, action) => {
      state.loading = false;
      state.achievements = action.payload;
    });

    builder.addCase(claimReward.pending, (state, action) => {
      state.pendingClaim = action.meta.arg;
    });
    builder.addCase(claimReward.rejected, (state) => {
      state.pendingClaim = null;
    });
    builder.addCase(claimReward.fulfilled, (state, action) => {
      state.pendingClaim = null;
      state.achievements = action.payload;
    });

    builder.addCase(levelUp.pending, (state) => {
      state.pending = true;
      state.loading = true;
    });
    builder.addCase(levelUp.fulfilled, (state, action) => {
      state.pending = false;
      state.loading = false;
      state.profile = action.payload;
    });
    builder.addCase(levelUp.rejected, (state) => {
      state.pending = false;
      state.loading = false;
    });
    builder.addCase(setWalletStore.fulfilled, (state, action) => {
      state.pending = false;
      state.store_user = action.payload;
    });
    builder.addCase(setWalletStore.rejected, (state) => {
      state.store_user = null;
    });
  },
});

export default userSlice.reducer;
