import { CONFIG } from 'constants/config';

import axios from 'axios';
import { useDomain } from 'helpers/utils';
import { Cookies } from 'react-cookie';

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

const cookies = new Cookies();
class BaseApi {
  public baseURL: string;
  public instance: AxiosInstance;

  public constructor(baseUrl: string, noToken?: boolean, refresh?: boolean) {
    if (!baseUrl) {
      throw new Error('baseUrl is REQUIRED!');
    }

    this.baseURL = baseUrl;

    this.instance = axios.create({
      baseURL: this.baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.instance.interceptors.request.use((config) => {
      const token = cookies.get(CONFIG.tokenKey);
      if (token && !noToken) {
        config.headers.authorization = token;
      }
      return config;
    });

    if (refresh) {
      this.instance.interceptors.response.use(
        (response) => response,
        async (error) => {
          const config = error?.config;
          const domain = useDomain();
          if (error?.response?.status === 401) {
            if (!config?._retry) {
              config._retry = true;
              const refresh_token = cookies.get(CONFIG.refreshTokenKey);
              if (refresh_token) {
                const result = await axios.post(`${this.baseURL}/api/auth/refresh-token`, {
                  refreshToken: refresh_token,
                });
                const { accessToken, gameToken, refreshToken } = result?.data;
                if (accessToken) {
                  cookies.set(CONFIG.tokenKey, accessToken, { domain: domain, maxAge: 18000000 });
                  cookies.set(CONFIG.gameTokenKey, gameToken, { domain: domain, maxAge: 18000000 });
                  cookies.set(CONFIG.refreshTokenKey, refreshToken, { domain: domain, maxAge: 2592000000 });
                  config.headers = {
                    ...config.headers,
                    authorization: accessToken,
                  };
                  return this.instance(config);
                }
              }
            } else {
              cookies.remove(CONFIG.tokenKey, { domain: domain });
              cookies.remove(CONFIG.gameTokenKey, { domain: domain });
              cookies.remove(CONFIG.refreshTokenKey, { domain: domain });
            }
          }
          return Promise.reject(error);
        }
      );
    }
  }

  public callApi<T>(options: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const { method, ...rest } = options;
    const reqMethod = method || 'GET';

    return this.instance({ method: reqMethod, ...rest });
  }
}

export default BaseApi;

export const mainApi = new BaseApi(CONFIG.servers.main, false, true);

export const gameApi = new BaseApi(CONFIG.servers.game);

export const storeApi = new BaseApi(CONFIG.servers.store, true);

export const claimApi = new BaseApi(CONFIG.servers.claim);

export const rewardApi = new BaseApi(CONFIG.servers.rewards);

export const metadataApi = new BaseApi(CONFIG.servers.metadata);

export const metaBodApi = new BaseApi(CONFIG.servers.metabod, true);

export const klaviyoApi = new BaseApi(CONFIG.urls.klaviyo, true);

export const gitexApi = new BaseApi(CONFIG.servers.gitex, true);
