import { createAsyncThunk } from '@reduxjs/toolkit';

import { getNotificationApi, disableNotificationApi, enableNotificationApi } from '../../apis/notification';

export const getNotification = createAsyncThunk('notification/get-notifications', async (_, thunkAPI) => {
  try {
    return getNotificationApi();
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const disableNotification = createAsyncThunk('notification/disable-notification', async (_, thunkAPI) => {
  try {
    return disableNotificationApi();
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const enableNotification = createAsyncThunk('notification/enable-notification', async (_, thunkAPI) => {
  try {
    return enableNotificationApi();
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});
