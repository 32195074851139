import { mainApi } from './BaseApi';

export const levelUpApi = <T>(wallet: string) =>
  mainApi
    .callApi<T>({
      method: 'POST',
      url: `/api/rank/claim/${wallet}`,
    })
    .then((result) => result.data);

export const getAchievementsApi = <T>(wallet: string) =>
  mainApi
    .callApi<T>({
      method: 'GET',
      url: `/api/reward/get-user-reward-progress/${wallet}`,
    })
    .then((result) => result.data);

export const claimRewardApi = <T>(achievementLvlId: string) =>
  mainApi
    .callApi<T>({
      method: 'POST',
      url: `/api/reward/claim-reward/`,
      data: {
        achievementLvlId,
      },
    })
    .then((result) => result.data);
