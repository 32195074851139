import { createContext, useContext } from 'react';

import type { FC, PropsWithChildren } from 'react';

const FEATURES = {
  landsMintOnSite: true,
  gameLeaderBoard: true,
  enableAuth2: process.env.REACT_APP_DISABLE_AUTH2 !== 'true',
};

type FeaturesState = typeof FEATURES;

export const FeaturesContext = createContext<FeaturesState>(FEATURES);

export const FeaturesProvider: FC<PropsWithChildren> = ({ children }) => {
  return <FeaturesContext.Provider value={FEATURES}>{children}</FeaturesContext.Provider>;
};

export const useFeatures = () => useContext(FeaturesContext);
