import { useCallback, useEffect, useState } from 'react';
import { MediaQueries } from 'styles/theme/mediaQueries';

export type DimensionType = {
  width: number;
  height: number;
  desktop?: boolean;
  smallDevice?: boolean;
  lgMobile?: boolean;
  sMobile?: boolean;
  landscape: boolean;
};

export const initDimension = {
  width: 0,
  height: 0,
  landscape: false,
};

const useWindowSize = (): DimensionType => {
  const [dimension, setDimension] = useState<DimensionType>(initDimension);

  const handleResizeWindow = useCallback(() => {
    const h = window.innerHeight;
    const w = window.innerWidth;

    setDimension({
      width: w,
      height: h,
      desktop: w < MediaQueries.MIN_WIDTHS.xl,
      smallDevice: w <= MediaQueries.MIN_WIDTHS.md,
      lgMobile: w <= MediaQueries.MIN_WIDTHS.lm,
      sMobile: w <= MediaQueries.MIN_WIDTHS.sm,
      landscape: w > h,
    });
  }, []);

  useEffect(() => {
    handleResizeWindow();
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, [handleResizeWindow]);

  return dimension;
};

export default useWindowSize;
