import { CONFIG } from 'constants/config';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { errorNotify, MESSAGE_KEYS, successNotify } from 'functions/notification';
import { extractResponseCode } from 'helpers/extractResponseCode';
import { useDomain } from 'helpers/utils';
import jwt from 'jsonwebtoken';
import { Cookies } from 'react-cookie';

import type { ReferralInfoModel } from '../../types/Referral';
import type { AchievementModel, UserLoginModel, UserProfileModel } from '../../types/user';

import { getUserAssetsApi } from '../../apis/assets';
import { loginWithEmailApi } from '../../apis/auth2';
import { claimRewardApi, getAchievementsApi, levelUpApi } from '../../apis/rank';
import { getMeApi, getReferralInfoApi, getUserInfoApi, setWalletStoreApi } from '../../apis/user';

type LevelUp = {
  code: string;
  link: string;
  message: string;
};
const cookies = new Cookies();

export const setWalletStore = createAsyncThunk('users/set_store_wallet', async (ethAddress: string, thunkAPI) => {
  try {
    return setWalletStoreApi(ethAddress);
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const userLoginWithEmail = createAsyncThunk(
  'user/login-with-email',
  async (payload: Parameters<typeof loginWithEmailApi>[0], thunkAPI) => {
    try {
      const loginResponse = await loginWithEmailApi(payload);
      if (loginResponse && loginResponse.accessToken) {
        const domain = useDomain();
        cookies.set(CONFIG.tokenKey, loginResponse.accessToken, { domain: domain, maxAge: 18000000 });
        cookies.set(CONFIG.gameTokenKey, loginResponse.gameToken, { domain: domain, maxAge: 18000000 });
        cookies.set(CONFIG.refreshTokenKey, loginResponse.refreshToken, { domain: domain, maxAge: 2592000000 });
      }
      if (loginResponse.walletId) {
        thunkAPI.dispatch(setWalletStore(loginResponse.walletId));
      }
      return loginResponse;
    } catch (error) {
      const errorCode = extractResponseCode(error);
      return thunkAPI.rejectWithValue({ code: errorCode, error: error.message });
    }
  }
);

export const getProfile = createAsyncThunk('user/get_profile_info', async (ethAddress: string, thunkAPI) => {
  try {
    const referral = await getReferralInfoApi<ReferralInfoModel>(ethAddress);
    const profile = await getUserInfoApi<UserProfileModel>(ethAddress);
    return { referral, profile };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getMeAction = createAsyncThunk('user/get_me', async (_, thunkAPI) => {
  try {
    const token = cookies.get(CONFIG.tokenKey);
    if (!token) {
      return thunkAPI.rejectWithValue({ error: 'Can not find access token' });
    }

    const prev = jwt.decode(token || '') as UserLoginModel;

    const myProfile = await getMeApi();
    const curr = jwt.decode(myProfile.accessToken) as UserLoginModel;
    if (
      prev.userEmail !== curr.userEmail ||
      prev.walletId !== curr.walletId ||
      prev.userId !== curr.userId ||
      prev.isVerified !== curr.isVerified ||
      prev.imxVerified !== curr.imxVerified
    ) {
      const domain = useDomain();
      cookies.set(CONFIG.tokenKey, myProfile.accessToken, { domain: domain, maxAge: 18000000 });
      cookies.set(CONFIG.gameTokenKey, myProfile.gameToken, { domain: domain, maxAge: 18000000 });
    }

    if (myProfile?.walletId) {
      thunkAPI.dispatch(setWalletStore(myProfile?.walletId));
    }
    return myProfile;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getAssets = createAsyncThunk('user/get_user_assets', async (wallet: string, thunkAPI) => {
  try {
    return getUserAssetsApi(wallet);
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getAchievements = createAsyncThunk('user/get_achievements', async (wallet: string, thunkAPI) => {
  try {
    return getAchievementsApi<AchievementModel[]>(wallet);
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const claimReward = createAsyncThunk('user/claim_reward', async (achievementLvlId: string, thunkAPI) => {
  try {
    return claimRewardApi<AchievementModel[]>(achievementLvlId);
  } catch (error) {
    errorNotify(extractResponseCode(error));
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const levelUp = createAsyncThunk('user/level_up', async (wallet: string, thunkAPI) => {
  try {
    const res = await levelUpApi<LevelUp>(wallet);
    successNotify(MESSAGE_KEYS.MINT_SUCCESS, { link: res.link });
    // to update user level progress bar on the profile page
    thunkAPI.dispatch(getMeAction());
    return getUserInfoApi<UserProfileModel>(wallet);
  } catch (error) {
    errorNotify(extractResponseCode(error));
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});
