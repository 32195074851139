import { userSlice } from './slices';
export const { logoutUser, updateUserPicture, cancelLoadingProfile, hideVerifyBox, setSimplifiedUser, setStoreUser } =
  userSlice.actions;
export {
  claimReward,
  getAchievements,
  getAssets,
  getMeAction,
  getProfile,
  levelUp,
  userLoginWithEmail,
} from './actions';
