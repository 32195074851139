import { FeaturesProvider } from '@context/FeaturesContext';
import { WagmiProvider } from '@context/WagmiProvider';
import WindowSizeContextProvider from '@context/WindowSizeContext';
import { Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from 'styles/theme/globalStyles';
import { theme } from 'styles/theme/theme';

import RootApp from './Apps/app';
import { store } from './store';

import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <WagmiProvider>
      <FeaturesProvider>
        <CookiesProvider>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              <WindowSizeContextProvider>
                <Suspense fallback={<div />}>
                  <RootApp />
                </Suspense>
              </WindowSizeContextProvider>
            </ThemeProvider>
          </BrowserRouter>
        </CookiesProvider>
      </FeaturesProvider>
    </WagmiProvider>
  </Provider>
);
