import { CONFIG } from 'constants/config';

import type { SiweMessage } from 'siwe';
import type { ApiResponse } from 'types/ApiResponse';
import type { UserLoginModel } from '../types/user';

import { mainApi } from './BaseApi';

export const loginWithEmailApi = (payload: { email: string; password: string; remember?: boolean }) =>
  mainApi
    .callApi<{
      userId: string;
      username: string;
      email: string;
      walletId?: string;
      isVerified: boolean;
      accessToken: string;
      refreshToken?: string;
      gameToken?: string;
    }>({
      method: 'POST',
      url: '/api/auth/login-with-email',
      data: payload,
    })
    .then((result) => result.data);

export const getNonceApi = async (wallet: string) =>
  mainApi
    .callApi<{ nonce: string }>({
      method: 'POST',
      url: `/api/auth/nonce`,
    })
    .then((result) => {
      localStorage.removeItem(CONFIG.storage.referralRefId);
      return result.data;
    });

export const loginWithWalletApi = (payload: { message: SiweMessage; signature: string; remember?: boolean }) => {
  const refId = localStorage.getItem(CONFIG.storage.referralRefId) ?? '';
  const clanName = localStorage.getItem(CONFIG.clanNameKey) ?? '';
  if (refId) payload['referralRefId'] = refId;
  if (clanName) payload['clanName'] = clanName;

  return mainApi
    .callApi<UserLoginModel>({
      method: 'POST',
      url: `/api/auth/login-with-wallet`,
      data: payload,
    })
    .then((result) => result.data);
};

export const registerWithEmailApi = (payload: {
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  referralRefId: string;
}) => {
  const clanName = localStorage.getItem(CONFIG.clanNameKey) ?? '';
  if (clanName) payload['clanName'] = clanName;

  return mainApi
    .callApi({
      method: 'POST',
      url: '/api/auth/register',
      data: payload,
    })
    .then((result) => result.data);
};

export const registerWithWalletApi = (walletId: string) =>
  mainApi
    .callApi<{
      id: string;
      walletId: string;
      isVerified: boolean;
    }>({
      method: 'POST',
      url: `/api/auth/register/:${walletId}`,
    })
    .then((result) => result.data);

export const requestEmailVerifyApi = (email: string) =>
  mainApi
    .callApi({
      method: 'POST',
      url: '/api/auth/request-code',
      data: { email },
    })
    .then((result) => result.data);

export const requestRestPasswordApi = (email: string) =>
  mainApi
    .callApi({
      method: 'POST',
      url: '/api/auth/request-reset-password',
      data: { email },
    })
    .then((result) => result.data);

export const resetPasswordApi = (payload: {
  userId: string;
  token: string;
  password: string;
  confirmPassword: string;
}) =>
  mainApi
    .callApi<ApiResponse>({
      method: 'PUT',
      url: '/api/auth/reset-password',
      data: payload,
    })
    .then((result) => result.data);

export const updatePasswordApi = (payload: { userId: string; password: string; confirmPassword: string }) =>
  mainApi
    .callApi<ApiResponse>({
      method: 'PUT',
      url: '/api/auth/update-password',
      data: payload,
    })
    .then((result) => result.data);

export const updateFullCredsApi = (payload: {
  username: string;
  userEmail: string;
  password: string;
  confirmPassword: string;
}) =>
  mainApi
    .callApi<ApiResponse>({
      method: 'PUT',
      url: '/api/auth/update-full-creds',
      data: payload,
    })
    .then((result) => result.data);

export const updateUsernameApi = (payload: { username: string }) =>
  mainApi
    .callApi<ApiResponse>({
      method: 'PUT',
      url: '/api/auth/set-username',
      data: payload,
    })
    .then((result) => result.data);

export const updateUserEmailApi = (payload: { email: string }) =>
  mainApi
    .callApi<ApiResponse>({
      method: 'PUT',
      url: '/api/auth/set-email',
      data: payload,
    })
    .then((result) => result.data);

export const updateUserWalletApi = (payload: { walletId: string }) =>
  mainApi
    .callApi<ApiResponse>({
      method: 'PUT',
      url: '/api/auth/set-wallet',
      data: payload,
    })
    .then((result) => result.data);
