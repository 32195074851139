export enum Chain {
  main = '0x1',
  polygon = '0x89',
  goerli = '0x5',
  mumbai = '0x13881',
  elysium_test = '0x53a',
  elysium = '0x53b',
  arbitrum_test = '0x66eed',
  arbitrum = '0xa4b1',
}

export enum NetEnum {
  ethereum = 'ethereum',
  polygon = 'polygon',
  elysium = 'elysium',
  arbitrum = 'arbitrum',
}
