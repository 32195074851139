import { createAsyncThunk } from '@reduxjs/toolkit';

import { getReleaseBalancesApi } from '../../apis/rewards';

export const getReleaseBalances = createAsyncThunk('release/get-balances', async (wallet: string, thunkAPI) => {
  try {
    return getReleaseBalancesApi(wallet);
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error?.message });
  }
});
