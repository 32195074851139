import type { LandModel, LandNFTModel } from 'types/Land';

import { gameApi, mainApi, metaBodApi, metadataApi } from './BaseApi';

export const getUserAssetsApi = <T>(wallet: string) =>
  mainApi
    .callApi<T>({
      url: `/api/assets/user/${wallet}`,
    })
    .then((result) => result.data);

export const getUserAvatarsApi = <T>(wallet: string) =>
  mainApi
    .callApi<T>({
      url: `/api/assets/avatars/u/${wallet}`,
    })
    .then((result) => result.data);

export const getAvatarApi = <T>(id: number | string) =>
  metadataApi
    .callApi<T>({
      url: `/goon/${id}`,
    })
    .then((result) => result.data);

export const getLandMetadataApi = (id: number | string) =>
  metadataApi
    .callApi<LandNFTModel>({
      url: `/land/${id}`,
    })
    .then((result) => result.data);

export const getLandMetadataForWebApi = (id: number | string) =>
  metadataApi
    .callApi<LandModel>({
      url: `/land-web/${id}`,
    })
    .then((result) => result.data);

export const getUserBodsApi = <T>(wallet: string) =>
  mainApi
    .callApi<T>({
      url: `/api/assets/bods/u/${wallet}`,
    })
    .then((result) => result.data);

export const getUserLandsApi = <T>(wallet: string) =>
  mainApi
    .callApi<T>({
      url: `/api/assets/lands/u/${wallet}`,
    })
    .then((result) => result.data);

export const getBodApi = <T>(id: number | string) =>
  metaBodApi
    .callApi<T>({
      url: `/metadata/${id}`,
    })
    .then((result) => result.data);

export const getUserCardsApi = <T>(wallet: string) =>
  mainApi
    .callApi<T>({
      url: `/api/assets/cards/u/${wallet}`,
    })
    .then((result) => result.data);

export const getAllCardApi = <T>() =>
  mainApi
    .callApi<T>({
      url: '/api/cards/all',
    })
    .then((result) => result.data);

export const getReleasedCardsApi = <T>() =>
  gameApi
    .callApi<T>({
      url: '/api/cards/released',
    })
    .then((result) => result.data);

export const getCardStatusApi = () =>
  gameApi
    .callApi<Array<{ custom_id: string; is_enabled: boolean }>>({
      url: 'v1/enabled_cards/',
    })
    .then((result) => result.data);

export const updateAvatarNameApi = <T>(newName: string, id: number | string) =>
  mainApi
    .callApi<T>({
      method: 'POST',
      url: `/api/avatars/update-name/${id}`,
      data: {
        name: newName,
      },
    })
    .then((result) => result.data);

export const resetAvatarNameApi = <T>(id: number | string) =>
  mainApi
    .callApi<T>({
      method: 'POST',
      url: `/api/avatars/reset-name/${id}`,
    })
    .then((result) => result.data);

export const setUserPictureApi = <T>(url: string) =>
  mainApi
    .callApi<T>({
      method: 'POST',
      url: '/api/users/profile-image',
      data: {
        imageUrl: url,
      },
    })
    .then((result) => result.data);
