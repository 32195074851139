export class Spacing {
  public static readonly unit = 4;

  public static get 1() {
    return `${Spacing.unit}px`;
  }

  public static get 2() {
    return `${Spacing.unit * 2}px`;
  }

  public static get 2.5() {
    return `${Spacing.unit * 2.5}px`;
  }

  public static get 3() {
    return `${Spacing.unit * 3}px`;
  }

  public static get 4() {
    return `${Spacing.unit * 4}px`;
  }

  public static get 5() {
    return `${Spacing.unit * 5}px`;
  }

  public static get 6() {
    return `${Spacing.unit * 6}px`;
  }

  public static get 7() {
    return `${Spacing.unit * 7}px`;
  }

  public static get 8() {
    return `${Spacing.unit * 8}px`;
  }

  public static get 9() {
    return `${Spacing.unit * 9}px`;
  }

  public static get 10() {
    return `${Spacing.unit * 10}px`;
  }

  public static get 11() {
    return `${Spacing.unit * 11}px`;
  }

  public static get 12() {
    return `${Spacing.unit * 12}px`;
  }

  public static get 13() {
    return `${Spacing.unit * 13}px`;
  }

  public static get 14() {
    return `${Spacing.unit * 14}px`;
  }

  public static get 15() {
    return `${Spacing.unit * 15}px`;
  }

  public static get 16() {
    return `${Spacing.unit * 16}px`;
  }

  public static get 20() {
    return `${Spacing.unit * 20}px`;
  }

  public static get 25() {
    return `${Spacing.unit * 25}px`;
  }

  public static get 26() {
    return `${Spacing.unit * 26}px`;
  }

  public static get 30() {
    return `${Spacing.unit * 30}px`;
  }
}
