import { createContext } from 'react';
import useWindowSize, { initDimension } from 'hooks/useWindowSize';

import type { FC } from 'react';
import type { DimensionType } from 'hooks/useWindowSize';

export const WindowSizeContext = createContext<DimensionType>(initDimension);

type WindowSizeContextProps = {
  children?: React.ReactNode;
};

const WindowSizeContextProvider: FC<WindowSizeContextProps> = ({ children }) => {
  const { landscape, width, height, smallDevice, desktop, lgMobile, sMobile } = useWindowSize();

  return (
    <WindowSizeContext.Provider
      value={{
        width,
        height,
        desktop,
        smallDevice,
        lgMobile,
        sMobile,
        landscape,
      }}
    >
      {children}
    </WindowSizeContext.Provider>
  );
};

export default WindowSizeContextProvider;
