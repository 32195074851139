export enum ENV_TYPES {
  development = 'development',
  staging = 'staging',
  production = 'production',
}

const getAppEnv = () => {
  if (process.env.REACT_APP_ENV === ENV_TYPES.staging) {
    return ENV_TYPES.staging;
  }
  if (process.env.REACT_APP_ENV === ENV_TYPES.production) {
    return ENV_TYPES.production;
  }
  return ENV_TYPES.development;
};

export const APP_ENV: ENV_TYPES = getAppEnv();
