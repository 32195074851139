import { createAsyncThunk } from '@reduxjs/toolkit';

import type { ReleasedCardListModel, SimpleAssetModel } from 'types/Asset';
import type { GalleryItemModel, SimpleCardModel } from '../../types/Card';

import {
  getAllCardApi,
  getCardStatusApi,
  getReleasedCardsApi,
  getUserAvatarsApi,
  getUserBodsApi,
  getUserCardsApi,
  getUserLandsApi,
} from '../../apis/assets';

export const getAllCards = createAsyncThunk('assets/get_all_cards', async (_, thunkAPI) => {
  try {
    const card_status = await getCardStatusApi();
    const card_list = await getAllCardApi<GalleryItemModel[]>();
    const groupById = card_status.reduce((acc, curr) => {
      acc[curr.custom_id] = curr.is_enabled;
      return acc;
    }, {});

    return card_list.map((card) => {
      if (groupById[card.custom_id]) {
        return {
          ...card,
          is_enabled: groupById[card.custom_id],
        };
      }
      return {
        ...card,
        is_enabled: false,
      };
    });
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getMyCards = createAsyncThunk('assets/get_my_cards', async (wallet: string, thunkAPI) => {
  try {
    return await getUserCardsApi<SimpleCardModel[]>(wallet);
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getMyAvatars = createAsyncThunk('assets/get_my_avatars', async (wallet: string, thunkAPI) => {
  try {
    return await getUserAvatarsApi<SimpleAssetModel[]>(wallet);
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getMyBods = createAsyncThunk('assets/get_my_bods', async (wallet: string, thunkAPI) => {
  try {
    return await getUserBodsApi<SimpleAssetModel[]>(wallet);
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getMyLands = createAsyncThunk('assets/get_my_lands', async (wallet: string, thunkAPI) => {
  try {
    return await getUserLandsApi<SimpleAssetModel[]>(wallet);
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getReleasedCards = createAsyncThunk('assets/use_released_cards', async (_, thunkAPI) => {
  try {
    return await getReleasedCardsApi<ReleasedCardListModel>();
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});
