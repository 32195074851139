import { createSlice } from '@reduxjs/toolkit';

import {
  loadExploreResource,
  loadMainResource,
  loadPackResource,
  loadResources,
  updateExplorePercent,
  updateMainPercent,
  updatePackPercent,
  updatePercent,
} from './actions';

type LandHomeState = {
  percent: number;
  source1: string;
  source2: string;
  canPlay: boolean;
  showMintAlarm: boolean;
};

type MainLoadState = {
  percent: number;
  source: string;
  canPlay: boolean;
};

type PackLoadState = {
  percent: number;
  sources: string[];
  canPlay: boolean;
};

type ExploreLoadState = {
  percent: number;
  total: number;
  loaded: number;
  canPlay: boolean;
};

export type UiState = {
  landHome: LandHomeState;
  main: MainLoadState;
  pack: PackLoadState;
  explore: ExploreLoadState;
  hasPopup: boolean;
};

const initialState: UiState = {
  landHome: {
    percent: 0,
    source1: '',
    source2: '',
    canPlay: false,
    showMintAlarm: true,
  },
  main: {
    percent: 0,
    source: '',
    canPlay: false,
  },
  pack: {
    percent: 0,
    sources: [],
    canPlay: false,
  },
  explore: {
    percent: 0,
    total: 1,
    loaded: 0,
    canPlay: false,
  },
  hasPopup: false,
};

export const uiSlice = createSlice({
  name: 'uiSlice',
  initialState,
  reducers: {
    setCanPlay(state, action) {
      state.landHome.canPlay = action.payload;
    },
    togglePopup(state, action) {
      state.hasPopup = action.payload;
    },
    toggleMintAlarm(state, action) {
      state.landHome.showMintAlarm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadResources.pending, (state) => {
      state.landHome.percent = 0;
      state.landHome.source1 = '';
      state.landHome.source1 = '';
    });
    builder.addCase(loadResources.fulfilled, (state, action) => {
      const [s1] = action.payload;
      state.landHome.source1 = s1 as string;
      // state.landHome.source2 = s2 as string;
      state.landHome.canPlay = true;
    });
    builder.addCase(updatePercent.fulfilled, (state, action) => {
      state.landHome.percent = action.payload;
    });
    builder.addCase(loadMainResource.pending, (state) => {
      state.main.percent = 0;
      state.main.source = '';
    });
    builder.addCase(loadMainResource.fulfilled, (state, action) => {
      const [s] = action.payload;
      state.main.source = s as string;
      state.main.canPlay = true;
    });
    builder.addCase(updateMainPercent.fulfilled, (state, action) => {
      state.main.percent = action.payload;
    });
    builder.addCase(loadPackResource.pending, (state) => {
      state.pack.percent = 0;
      state.pack.sources = [];
    });
    builder.addCase(loadPackResource.fulfilled, (state, action) => {
      state.pack.sources = action.payload as string[];
      state.pack.canPlay = true;
    });
    builder.addCase(updatePackPercent.fulfilled, (state, action) => {
      state.pack.percent = action.payload;
    });
    builder.addCase(loadExploreResource.fulfilled, (state, action) => {
      state.explore.total = action.payload;
    });
    builder.addCase(updateExplorePercent.fulfilled, (state, action) => {
      state.explore.percent = action.payload.percent;
      state.explore.loaded = action.payload.loaded;
    });
  },
});

export default uiSlice.reducer;
