import { createSlice } from '@reduxjs/toolkit';

import { getReservePeriod, getReservePrice, getTotalReserved, buyLand } from './reserveActions';

export interface ReserveState {
  loading: boolean;
  prices: {
    actual_price: number;
    base_price: number;
    discount: number;
    goon_num: number;
    gob_balance: number;
    plots_bought: number;
  };
  periods: {
    reserve: {
      start: 'string';
      end: 'string';
    };
    presale: {
      start: 'string';
      end: 'string';
    };
    public: {
      start: 'string';
      end: 'string';
    };
    stake: {
      start: 'string';
      end: 'string';
    };
  };
  totalReserved: number;
  success: boolean;
  error: boolean;
}

const initialState: ReserveState = {
  prices: null,
  periods: null,
  totalReserved: 0,
  loading: false,
  success: false,
  error: false,
};

export const authSlice = createSlice({
  name: 'reserve',
  initialState,
  reducers: {
    setIinitialized: (state) => {
      state.loading = true;
      state.prices = null;
      state.totalReserved = 0;
      state.success = false;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTotalReserved.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getTotalReserved.fulfilled, (state, action) => {
      state.loading = false;
      state.totalReserved = (action.payload as any)?.totalLand;
    });
    builder.addCase(getReservePrice.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getReservePrice.fulfilled, (state, action) => {
      state.loading = false;
      state.prices = action.payload as any;
    });
    builder.addCase(buyLand.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(buyLand.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
    builder.addCase(buyLand.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    });
    builder.addCase(getReservePeriod.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getReservePeriod.fulfilled, (state, action) => {
      state.loading = false;
      state.periods = action.payload as any;
    });
  },
});

export default authSlice.reducer;
