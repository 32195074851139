import { mainApi, storeApi } from './BaseApi';

export const userAvatarStatusApi = <T>() =>
  storeApi.callApi<T>({ url: '/api/v1/user_avatars_stats', withCredentials: true }).then((result) => result.data);

export const getSupplyLimitApi = <T>() =>
  storeApi.callApi<T>({ url: '/api/v1/get_supply_limit_info', withCredentials: true }).then((result) => result.data);

export const claimPackWidthAvatarsApi = <T>() =>
  storeApi
    .callApi<T>({
      method: 'POST',
      url: '/api/v1/claim_all_packs_with_avatars',
      withCredentials: true,
    })
    .then((result) => result.data);

export const claimAvatarsByGobApi = <T>(packCount: number) =>
  storeApi
    .callApi<T>({
      url: '/api/v1/claim_with_gob',
      method: 'POST',
      withCredentials: true,
      data: {
        number_of_packs_to_purchase: packCount,
      },
    })
    .then((result) => result.data);

export const getFloorPriceApi = <T>() =>
  mainApi
    .callApi<T>({
      url: `/api/chain/floor-prices/`,
    })
    .then((result) => result.data);
