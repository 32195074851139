import { createGlobalStyle, css } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    body {
        color: ${({ theme }) => theme.colors.gray100};
        background-color: ${({ theme }) => theme.colors.gray};
    }

    h1, h2, h3, h4, h5, h6 {
        color: ${({ theme }) => theme.colors.gray100};
        margin-bottom: 0px;
    }

    p {
        margin-top: 0;
        margin-bottom: 0px;
    }

    .text-center {
        text-align: center;
    }

    .h2-32-extra {
        ${({ theme }) => theme.typography.h232Extra};
    }

    .h3-24-extra {
        ${({ theme }) => theme.typography.h324Extra};
    }

    .b1-18-regular {
        ${({ theme }) => theme.typography.b118Regular};
    }

    .b2-16-regular {
        ${({ theme }) => theme.typography.b216Regular};
    }

    .b2-16-bold {
        ${({ theme }) => theme.typography.b216Bold};
    }

    .ant-select-dropdown {
        border: 1px solid;
        border-radius: ${({ theme }) => theme.spacing[4]};
        border-color: ${({ theme }) => theme.colors.gray30};
        background-color: ${({ theme }) => theme.colors.gray};
        padding: 8px 0;

        .ant-select-item {
            padding: 8px 16px;
        }

        .ant-select-item-option-content {
            ${({ theme }) => theme.typography.b216Regular};
            color: ${({ theme }) => theme.colors.gray100};
        }

        .ant-select-item-option-active,
        .ant-select-item-option-selected {
            color: ${({ theme }) => theme.colors.green100};
            background-color: transparent;

            .ant-select-item-option-content {
                color: ${({ theme }) => theme.colors.green100};
            }
        }

        .ant-select-item-option-selected {
            .ant-select-item-option-state {
                color: ${({ theme }) => theme.colors.green100};
            }
        }
    }

    .ant-tabs-content {
        max-width: 100vw;
        overflow: hidden;
    }

    .ant-popover-content {
        border-radius: ${({ theme }) => theme.spacing[6]};
        background-color: ${({ theme }) => theme.colors.gray10};
        padding: ${({ theme }) => theme.spacing[8]};
        max-width: 343px;

        .ant-popover-inner {
            box-shadow: unset;
            background: transparent;

            .ant-popover-inner-content {
                padding: 0;
            }
        }
        .ant-popover-arrow-content {
            background-color: ${({ theme }) => theme.colors.gray10};
        }
        .ant-popover-arrow-content:before {
            background-color: ${({ theme }) => theme.colors.gray10};
        }

    }

    .ant-modal-wrap {
      z-index: 1250;
    }

    .ant-modal-wrap.full-screen {
        height: 100vh;
        width: 100vw;
        margin: 0;
        top: 0;

        .ant-modal {
            top: 0;
            padding-bottom: 0px;
            max-width: 100vw;

            .ant-modal-body {
                padding: 0px;
            }
        }
    }

    .ant-modal-wrap.bg-transparent {
        .ant-modal-content {
            background-color: transparent;
            box-shadow:  none;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:active,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
        -webkit-text-fill-color: ${({ theme }) => theme.colors.gray100};
        -webkit-box-shadow: 0 0 0px 1000px #000 inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    .gob-tooltip {
        ${({ theme }) => css`
          &.ant-tooltip-placement-top,
          &.ant-tooltip-placement-topLeft,
          &.ant-tooltip-placement-topRight {
            padding-bottom: 0px;
          }
          .ant-tooltip-arrow {
            display: none;
          }
          .ant-tooltip-inner {
            ${theme.typography.b118Regular};
            color: ${theme.colors.gray100};
            background: ${theme.colors.gray10};
            border: 1px solid ${theme.colors.gray30};
            border-radius: ${theme.spacing[4]};
            padding: ${theme.spacing[3]} ${theme.spacing[4]};
          }
        `}
    }
`;

export default GlobalStyles;
